body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logo, #logo_white, #logo_footer {
  display: block;
  max-width: 8.5rem;
}

.bold {
  color: #3f3f3f !important;
  font-weight: 700;
  margin: 0;
}

#car .navbar.white-bg:not(.affix) .main-menu li a {
  color: white !important;
}

#car .navbar.white-bg:not(.affix) #logo {
  display: none;
}

#car .navbar.white-bg.affix #logo_white {
  display: none;
}